import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Event, ActivatedRoute } from '@angular/router';
import HasAnyAuthorityDirective from '../../shared/auth/has-any-authority.directive';
import SharedModule from '../../shared/shared.module';
import { filter } from 'rxjs/operators';
import ActiveMenuDirective from '../navbar/active-menu.directive';
import { HomeServiceService } from '../../home/service/home-service.service';
import { Subscription } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CartService } from '../../cart/cart.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'jhi-side-bar',
  standalone: true,
  imports: [RouterModule, SharedModule, HasAnyAuthorityDirective, ActiveMenuDirective, NgxSkeletonLoaderModule, CommonModule],
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  @Input() isInHome: String = '';
  @ViewChild('modalCartVide') modalCartVide: any;

  activeItem: string = 'pharmacies';
  pharmacyId: any;
  currentUrl: any;

  setActive(item: string) {
    this.activeItem = item;
  }
  contentLoaded = false;

  pharmacieDetails: any;
  currentSchedule: any;
  disable: boolean = false;
  idSubscription!: Subscription;
  constructor(
    private router: Router,
    private pharmacieService: HomeServiceService,
    private cartService: CartService,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.currentUrl = event.url;
    });
  }
  purchaseId: any;
  isPharmaciesRouteActive = false;
  isOrdersRouteActive = false;
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isPharmaciesRouteActive =
          this.router.url.includes('/home') ||
          this.router.url.includes('/pharmacie-category') ||
          this.router.url.includes('/pharmacie-sub-category') ||
          this.router.url.includes('/pharmacie-products');
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isOrdersRouteActive = this.router.url.includes('/orders') || this.router.url.includes('/order-details');
      }
    });
    this.getPurchaseID();
    this.pharmacieService.pharmacyIdSubjectData$.subscribe(value => {
      this.pharmacyId = value;
      this.getPharmacyById(this.pharmacyId);
    });
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.router.events
      .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.disable = this.checkRoute(event.urlAfterRedirects);
      });
    this.loadCartItems();
  }

  getPurchaseID() {
    this.cartService.purchaseId$.subscribe(res => {
      if (res) {
        this.purchaseId = res;
        console.log(res, 'purchaseId');
      } else {
        this.purchaseId = null;
      }
    });
  }
  documents: any;
  prescriptionUrls: string[] = [];
  vitalCardUrl: string[] = [];
  mutualCardUrl: string[] = [];

  cookiesCart!: any[];
  cartItems: any[] = [];

  loadCartItems(): void {
    this.cartService.cartItems$.subscribe(cartItems => {
      this.cookiesCart = cartItems;
      this.cartItems = cartItems;
    });
  }
  isCartAccessible(): boolean {
    const cartItemsNotEmpty = this.cartItems.length !== 0;
    return cartItemsNotEmpty || this.purchaseId;
  }

  async navigateToCart() {
    await this.getPurchaseID();
    console.log(this.purchaseId);
    if (this.isCartAccessible()) {
      this.router.navigate(['/cart']);
    } else {
      this.openModal();
    }
  }
  openModal() {
    this.modalService.open(this.modalCartVide, { size: 's', centered: true });
  }

  checkRoute(url: string): boolean {
    const routesToDisable = ['/pharmacie-category', '/pharmacie-sub-category', '/pharmacie-products'];
    return routesToDisable.some(route => url.includes(route));
  }

  getPharmacyById(pharmacyId: any) {
    console.log('pharmabyId');
    this.pharmacieService.getPharmacyById(pharmacyId).subscribe({
      next: res => {
        this.pharmacieDetails = res;
        console.log('pharmacieDetails', this.pharmacieDetails);
      },
      error: () => {
        console.error('Error fetching pharmacie:');
      },
    });
  }

  formatHour(openingHour: string | null, closingHour: string | null): string {
    if (!openingHour || !closingHour) return 'Horaires non disponibles';
    const formatPart = (part: string) => part.padStart(2, '0');
    const [openingHourPart, openingMinutePart] = openingHour.split(':');
    const [closingHourPart, closingMinutePart] = closingHour.split(':');
    const formattedOpeningHour = formatPart(openingHourPart);
    const formattedOpeningMinute = formatPart(openingMinutePart);
    const formattedClosingHour = formatPart(closingHourPart);
    const formattedClosingMinute = formatPart(closingMinutePart);
    return `${formattedOpeningHour}h${formattedOpeningMinute}–${formattedClosingHour}h${formattedClosingMinute}`;
  }
}
