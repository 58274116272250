<div class="row mt-5">
  <h2 class="lead" style="color: #141414">Liste des commandes</h2>
</div>
<div class="row">
  <div class="col-8">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink (click)="changeStatus('CREATED_WITH_PRESCRIPTION')">Nouvelle</a>
        <ng-template ngbNavContent>
          <div [ngClass]="createdWithPrescriptionPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Réf Commande</th>
                    <th scope="col">Date de création</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let purshase of purshases; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ purshase?.id }}</td>
                      <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                      <td>
                        {{ purshase?.deliveryAddress?.streetNumber }} {{ purshase?.deliveryAddress?.streetName }}
                        {{ purshase?.deliveryAddress?.city }} {{ purshase?.deliveryAddress?.state }}
                        {{ purshase?.deliveryAddress?.postalCode }} {{ purshase?.deliveryAddress?.country }}
                      </td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button
                            class="btn-payer"
                            (click)="acceptCommande(purshase?.id)"
                            *ngIf="purshase.lastStatus.name == 'CREATED_WITH_PRESCRIPTION'"
                          >
                            Accepter
                          </button>
                          <button class="btn-command btn-delete" (click)="onDeleteClick(purshase?.id)"></button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <!--
          -->
            <div class="card flex justify-content-center">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink (click)="changeStatus('IN_PROGRESS')">En cours</a>
        <ng-template ngbNavContent>
          <div [ngClass]="inProgressPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Réf Commande</th>
                    <th scope="col">Date de création</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let purshase of purshases; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ purshase?.id }}</td>
                      <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                      <td>{{ purshase?.totalAmount.toFixed(2) }}€</td>
                      <td>
                        {{ purshase?.deliveryAddress?.streetNumber }} {{ purshase?.deliveryAddress?.streetName }}
                        {{ purshase?.deliveryAddress?.city }} {{ purshase?.deliveryAddress?.state }}
                        {{ purshase?.deliveryAddress?.postalCode }}
                        {{ purshase?.deliveryAddress?.country }}
                      </td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer" (click)="confirmedCommande(purshase?.id)">Prête</button>
                          <button class="btn-command btn-delete" (click)="onDeleteClick(purshase?.id)"></button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" style="border: none">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"></p-paginator>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink (click)="changeStatus('PAYMENT_INSTANCE', 'READY')">Prête</a>
        <ng-template ngbNavContent>
          <div [ngClass]="paymentInstancePurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Réf Commande</th>
                    <th scope="col">Date de création</th>
                    <th scope="col">Montant</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Détails</th>
                    <th scope="col">État</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let purshase of purshases; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ purshase?.id }}</td>
                      <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                      <td>{{ purshase?.totalAmount.toFixed(2) }}€</td>
                      <td>
                        {{ purshase?.deliveryAddress?.streetNumber }} {{ purshase?.deliveryAddress?.streetName }}
                        {{ purshase?.deliveryAddress?.city }}
                        {{ purshase?.deliveryAddress?.postalCode }}
                        {{ purshase?.deliveryAddress?.country }}
                      </td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                        </div>
                      </td>
                      <td *ngIf="purshase.payment != null">Payé</td>
                      <td *ngIf="purshase.payment == null">En Attente</td>

                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer" [disabled]="purshase.payment == null">Emballer</button>
                          <button class="btn-command btn-delete" (click)="onDeleteClick(purshase?.id)"></button>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" style="border: none">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"></p-paginator>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink (click)="changeStatus('PACKAGING')">Emballage</a>
        <ng-template ngbNavContent>
          <div [ngClass]="packagingPurchasesCount > 8 ? 'scorllable-container ' : ''">
            <div class="table-container">
              <table class="table table-responsive">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Réf Commande</th>
                    <th scope="col">Date de création</th>
                    <th scope="col">Adresse</th>
                    <th scope="col">Livraison</th>
                    <th scope="col">Détails</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let purshase of purshases; let i = index">
                    <tr *ngIf="!contentLoaded">
                      <td colspan="5">
                        <ngx-skeleton-loader
                          count="1"
                          appearance="line"
                          [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                        ></ngx-skeleton-loader>
                      </td>
                    </tr>
                    <tr *ngIf="contentLoaded">
                      <td>{{ purshase?.id }}</td>
                      <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                      <td>
                        {{ purshase?.deliveryAddress?.streetNumber }} {{ purshase?.deliveryAddress?.streetName }}
                        {{ purshase?.deliveryAddress?.city }} {{ purshase?.deliveryAddress?.state }}
                        {{ purshase?.deliveryAddress?.postalCode }}
                        {{ purshase?.deliveryAddress?.country }}
                      </td>
                      <td>Click and collect</td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                        </div>
                      </td>
                      <td>
                        <div class="btn-wrap">
                          <button class="btn-payer">Livrer</button>
                          <!--
                          <button class="btn-command btn-localisation"></button>
                          <button class="btn-command btn-download"></button>
                        --></div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="card flex justify-content-center" style="border: none">
              <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"></p-paginator>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink>En Livraison</a>
        <ng-template ngbNavContent>
          <div class="table-container">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Réf Commande</th>
                  <th scope="col">Date de création</th>
                  <th scope="col">Étape</th>
                  <th scope="col">Code livreur</th>
                  <th scope="col">Code Client</th>
                  <th scope="col">Détails</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0714528/24</td>
                  <td>24/04/2024 10h45</td>
                  <td>Attente livreur</td>

                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-localisation"></button>
                      <!--<button class="btn-command btn-download"></button>-->
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-localisation"></button>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-view"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>0714528/24</td>
                  <td>24/04/2024 10h45</td>
                  <td>En route</td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-localisation"></button>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-localisation"></button>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-view"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>0714528/24</td>
                  <td>24/04/2024 10h45</td>
                  <td>Client Absent</td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-localisation"></button>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-localisation"></button>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-view"></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="6">
        <a ngbNavLink>Archivées</a>
        <ng-template ngbNavContent>
          <div class="table-container">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Réf Commande</th>
                  <th scope="col">Date de création</th>
                  <th scope="col">Statut</th>
                  <th scope="col">Détails</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0714528/24</td>
                  <td>24/04/2024 10h45</td>
                  <td>
                    <div class="btn-wrap">
                      <span class="badge bg-primary">Livrée</span>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-view"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>0714528/24</td>
                  <td>24/04/2024 10h45</td>
                  <td>
                    <div class="btn-wrap">
                      <span class="badge bg-danger">Annulée</span>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-view"></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>0714528/24</td>
                  <td>24/04/2024 10h45</td>
                  <td>
                    <div class="btn-wrap">
                      <span class="badge bg-success">Indisponible</span>
                    </div>
                  </td>
                  <td>
                    <div class="btn-wrap">
                      <button class="btn-command btn-view"></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </li>
      <!--
      -->
    </ul>
  </div>
  <div class="col-4">
    <div class="product-search m-2">
      <input
        type="text"
        class="form-control category-input"
        id="text"
        name="search"
        (keyup)="applyFilter($event)"
        placeholder="Recherche ..."
        data-cy="text"
      />
      <span class="search-icon"></span>
    </div>
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
