<div class="row mt-5 mb-4">
  <h2 class="lead" style="color: #0078c6">Liste des commandes</h2>
</div>
<div class="row">
  <div class="container">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" role="tablist">
      <li [ngbNavItem]="1">
        <a ngbNavLink (click)="changeStatus('CREATED_WITH_PRESCRIPTION')">Nouvelle</a>
        <ng-template ngbNavContent>
          <div class="table-container" *ngIf="hasCreatedWithPrescription">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Réf Commande</th>
                  <th scope="col">Pharmacie</th>
                  <th scope="col">Date de création</th>
                  <th scope="col">Détails</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let purshase of purshases; let i = index">
                  <tr *ngIf="!contentLoaded">
                    <td colspan="5">
                      <ngx-skeleton-loader
                        count="1"
                        appearance="line"
                        [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                      ></ngx-skeleton-loader>
                    </td>
                  </tr>
                  <tr *ngIf="contentLoaded">
                    <td>{{ purshase?.id }}</td>
                    <td>{{ purshase?.purchaseStockItems[0]?.stockItem?.pharmacy?.legalName ?? purshase?.pharmacy?.legalName }}</td>
                    <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                    <td>
                      <div class="btn-wrap">
                        <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                      </div>
                    </td>
                    <td>
                      <div class="btn-wrap">
                        <button class="btn-command btn-edit"></button>
                        <button class="btn-command btn-delete" (click)="onDeleteClick(purshase?.id)"></button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="card flex justify-content-center" style="border: none" *ngIf="hasCreatedWithPrescription">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
          </div>
          <ng-container *ngIf="!purshases.length">
            <lottie-player
              src="../../content/images/noData.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 250px; margin: auto"
              loop
              autoplay
            ></lottie-player>
          </ng-container>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink (click)="changeStatus('IN_PROGRESS', 'PAYMENT_INSTANCE')">En cours</a>
        <ng-template ngbNavContent>
          <div class="table-container" *ngIf="hasInProgressPurchases">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Réf Commande</th>
                  <th scope="col">Pharmacie</th>
                  <th scope="col">Date de création</th>
                  <th scope="col">Traitement</th>
                  <th scope="col">Détails</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let purshase of purshases; let i = index">
                  <tr *ngIf="!contentLoaded">
                    <td colspan="5">
                      <ngx-skeleton-loader
                        count="1"
                        appearance="line"
                        [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                      ></ngx-skeleton-loader>
                    </td>
                  </tr>
                  <tr *ngIf="contentLoaded">
                    <td>{{ purshase?.id }}</td>
                    <td>{{ purshase?.purchaseStockItems[0]?.stockItem?.pharmacy?.legalName ?? purshase?.pharmacy?.legalName }}</td>
                    <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                    <td>12h08</td>
                    <td>
                      <div class="btn-wrap">
                        <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                      </div>
                    </td>
                    <td>
                      <div class="btn-wrap">
                        <button
                          *ngIf="purshase.lastStatus.name == 'PAYMENT_INSTANCE'"
                          (click)="initiatePaymentProxy(purshase?.id)"
                          class="btn-payer"
                          [disabled]="purshase.payment != null"
                        >
                          Payer
                        </button>
                        <button class="btn-command btn-delete" (click)="onDeleteClick(purshase?.id)"></button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="card flex justify-content-center" style="border: none" *ngIf="hasInProgressPurchases">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"></p-paginator>
          </div>
          <ng-container *ngIf="!hasInProgressPurchases">
            <lottie-player
              src="../../content/images/noData.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 250px; margin: auto"
              loop
              autoplay
            ></lottie-player>
          </ng-container>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink (click)="changeStatus('PACKAGING', 'READY')">Prête</a>
        <ng-template ngbNavContent>
          <div class="table-container" *ngIf="hasPackagingReady">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Réf Commande</th>
                  <th scope="col">Pharmacie</th>
                  <th scope="col">Date de création</th>
                  <th scope="col">Détails</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let purshase of purshases; let i = index">
                  <tr *ngIf="!contentLoaded">
                    <td colspan="5">
                      <ngx-skeleton-loader
                        count="1"
                        appearance="line"
                        [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                      ></ngx-skeleton-loader>
                    </td>
                  </tr>
                  <tr *ngIf="contentLoaded">
                    <td>{{ purshase?.id }}</td>
                    <td>{{ purshase?.purchaseStockItems[0]?.stockItem?.pharmacy?.legalName ?? purshase?.pharmacy?.legalName }}</td>
                    <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                    <td>
                      <div class="btn-wrap">
                        <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                      </div>
                    </td>
                    <td>
                      <div class="btn-wrap">
                        <button class="btn-payer" [disabled]="purshase.payment != 'null'">Payer</button>
                        <button class="btn-command btn-delete" (click)="onDeleteClick(purshase?.id)"></button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="card flex justify-content-center" style="border: none" *ngIf="hasPackagingReady">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
          </div>
          <ng-container *ngIf="!hasPackagingReady">
            <lottie-player
              src="../../content/images/noData.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 250px; margin: auto"
              loop
              autoplay
            ></lottie-player>
          </ng-container>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink (click)="changeStatus('DELIVERING', 'RECOVERING')">En Livraison</a>
        <ng-template ngbNavContent>
          <div class="table-container" *ngIf="hasDelivering">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Réf Commande</th>
                  <th scope="col">Pharmacie</th>
                  <th scope="col">Date de création</th>
                  <!-- <th scope="col">Estimation</th>-->
                  <th scope="col">Détails</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let purshase of purshases; let i = index">
                  <tr *ngIf="!contentLoaded">
                    <td colspan="5">
                      <ngx-skeleton-loader
                        count="1"
                        appearance="line"
                        [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                      ></ngx-skeleton-loader>
                    </td>
                  </tr>
                  <tr *ngIf="contentLoaded">
                    <td>{{ purshase?.id }}</td>
                    <td>{{ purshase?.purchaseStockItems[0]?.stockItem?.pharmacy?.legalName ?? purshase?.pharmacy?.legalName }}</td>
                    <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                    <!-- <td>20 Minutes</td> -->
                    <td>
                      <div class="btn-wrap">
                        <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                      </div>
                    </td>
                    <td>
                      <div class="btn-wrap">
                        <button class="btn-command btn-localisation"></button>
                        <button class="btn-command btn-download"></button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="card flex justify-content-center" style="border: none" *ngIf="hasDelivering">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
          </div>
          <ng-container *ngIf="!hasDelivering">
            <lottie-player
              src="../../content/images/noData.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 250px; margin: auto"
              loop
              autoplay
            ></lottie-player>
          </ng-container>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink (click)="changeStatus('RECOVERED', 'RETRACTED', 'CANCELED', 'REJECTED', 'LOST')">Archivées</a>
        <ng-template ngbNavContent>
          <div class="table-container" *ngIf="hasRecoveredOrCanceled">
            <table class="table table-responsive">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Réf Commande</th>
                  <th scope="col">Pharmacie</th>
                  <th scope="col">Date de création</th>
                  <th scope="col">Statut</th>
                  <th scope="col">Détails</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let purshase of purshases; let i = index">
                  <tr *ngIf="!contentLoaded">
                    <td colspan="5">
                      <ngx-skeleton-loader
                        count="1"
                        appearance="line"
                        [theme]="{ height: '24px', width: '100%', 'margin-bottom': '10px' }"
                      ></ngx-skeleton-loader>
                    </td>
                  </tr>
                  <tr *ngIf="contentLoaded">
                    <td>{{ purshase?.id }}</td>
                    <td>{{ purshase?.purchaseStockItems[0]?.stockItem?.pharmacy?.legalName ?? purshase?.pharmacy?.legalName }}</td>
                    <td>{{ purshase?.orderDate | date: "dd/MM/yyyy HH'h'mm" }}</td>
                    <td>
                      <div class="btn-wrap">
                        <span class="badge bg-primary">Livrée</span>
                      </div>
                    </td>
                    <td>
                      <div class="btn-wrap">
                        <button class="btn-command btn-view" (click)="navigateToPurchaseDetails(purshase?.id)"></button>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="card flex justify-content-center" style="border: none" *ngIf="hasRecoveredOrCanceled">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" />
          </div>
          <ng-container *ngIf="!hasRecoveredOrCanceled">
            <lottie-player
              src="../../content/images/noData.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 250px; margin: auto"
              loop
              autoplay
            ></lottie-player>
          </ng-container>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
