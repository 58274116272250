import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OrdersService } from '../../../orders/orders.service';
import { PaginatorModule } from 'primeng/paginator';
import { debounceTime, distinctUntilChanged, Subject, switchMap } from 'rxjs';
import { HomeServiceService } from '../../../home/service/home-service.service';
import { ToastrService } from 'ngx-toastr';
interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'jhi-list-purchases',
  standalone: true,
  imports: [NgbModule, CommonModule, NgxSkeletonLoaderModule, PaginatorModule],
  templateUrl: './list-purchases.component.html',
  styleUrl: './list-purchases.component.scss',
})
export class ListPurchasesComponent implements OnInit {
  purshases: any;
  contentLoaded = false;
  first: number = 0;
  rows: number = 1;
  totalRecords: number = 0;
  searchInputSubject = new Subject<string>();
  paymentInstancePurchasesCount: number = 0;
  readyPurchasesCount: number = 0;
  packagingPurchasesCount: number = 0;
  inProgressPurchasesCount: number = 0;
  createdWithPrescriptionPurchasesCount: number = 0;
  currentStatuses: string[] = ['CREATED_WITH_PRESCRIPTION'];

  constructor(
    private router: Router,
    private orderService: OrdersService,
    private modalService: NgbModal,
    private pharmacieService: HomeServiceService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
    this.getPurshases(this.currentStatuses);
    this.searchInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchName => {
          const data = {
            contactName: searchName.trim().toLowerCase(),
          };
          console.log('%centreprises.component.ts line:203 data search', 'color: #007acc;', data);
          return this.pharmacieService.searchPurchsesPharmacist(searchName.trim().toLowerCase()).pipe();
        }),
      )
      .subscribe((res: any) => {
        this.purshases = res.content;
      });
  }
  acceptCommande(purshase_id: any) {
    let data = {
      event: 'PHARMACIST_ACCEPT',
      purchaseId: purshase_id,
    };
    this.orderService.acceptPurchases(data).subscribe({
      next: res => {
        this.getPurshases();
        this.toastr.success('Commande acceptée avec succès.');
      },
      error: err => {
        console.log(err);
        this.toastr.error("Échec de l'acceptation de commande.");
      },
    });
  }

  confirmedCommande(purshase_id: any) {
    let data = {
      event: 'PHARMACIST_FINISH',
      purchaseId: purshase_id,
    };
    this.orderService.ConfirmedPurchases(data).subscribe({
      next: res => {
        this.getPurshases();
        this.toastr.success('Commande confirmée avec succès.');
      },
      error: err => {
        console.log(err);
        this.toastr.error('Échec de confirmation de commande.');
      },
    });
  }

  onPageChange(event: any) {
    const pageEvent: PageEvent = {
      first: event.first ?? 0,
      rows: event.rows ?? 8,
      page: event.page ?? 0,
      pageCount: event.pageCount ?? 0,
    };

    this.first = pageEvent.first;
    this.rows = pageEvent.rows;
    this.getPurshases(this.currentStatuses);
  }
  changeStatus(...statuses: string[]) {
    console.log('Change status', statuses);
    this.first = 0; // Réinitialiser la pagination à la première page
    this.currentStatuses = statuses; // Mise à jour du statut sélectionné
    this.getPurshases(this.currentStatuses); // Appel de l'API avec les nouveaux statuts
  }
  getPurshases(statuses: string[] = ['CREATED_WITH_PRESCRIPTION']) {
    this.orderService.getPurchasesPharmacist(this.first, statuses).subscribe({
      next: res => {
        this.purshases = res.content;
        this.totalRecords = res.totalPages;
        this.paymentInstancePurchasesCount = this.purshases.filter(purchase => purchase.lastStatus.name === 'PAYMENT_INSTANCE').length;
        console.log('paymentInstancePurchasesCount', this.paymentInstancePurchasesCount);
        this.readyPurchasesCount = this.purshases.filter(purchase => purchase.lastStatus.name === 'READY').length;
        console.log('readyPurchasesCount', this.readyPurchasesCount);

        this.packagingPurchasesCount = this.purshases.filter(purchase => purchase.lastStatus.name === 'PACKAGING').length;
        console.log('packagingPurchasesCount', this.packagingPurchasesCount);
        this.inProgressPurchasesCount = this.purshases.filter(purchase => purchase.lastStatus.name === 'IN_PROGRESS').length;

        this.createdWithPrescriptionPurchasesCount = this.purshases.filter(
          purchase => purchase.lastStatus.name === 'CREATED_WITH_PRESCRIPTION',
        ).length;
      },
      error: err => {
        console.log(err);
      },
    });
  }
  onDeleteClick(purshase_id: any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) de vouloir supprimer cet commande ?',
      text: 'Cette action est irréversible !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, supprimer !',
    }).then(result => {
      if (result.isConfirmed) {
        this.deletePurshases(purshase_id);
      }
    });
  }
  deletePurshases(purshase_id: any) {
    this.orderService.deletePurchases(purshase_id).subscribe({
      next: res => {
        this.getPurshases();
      },
      error: err => {
        console.log(err);
      },
    });
  }
  navigateToPurchaseDetails(purchase_id: any) {
    this.router.navigate(['/pharmacie/details-purchase', { purchase_id: purchase_id }]);
  }
  openModal(modal: any) {
    this.modalService.open(modal, { size: 'lg', centered: true });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputSubject.next(filterValue);
  }
}
