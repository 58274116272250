import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '../../cart/cart.service';

@Component({
  selector: 'jhi-success',
  standalone: true,
  imports: [],
  templateUrl: './success.component.html',
  styleUrl: './success.component.scss',
})
export class SuccessComponent implements OnInit {
  constructor(
    private cartService: CartService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    console.log('Clearing cart items...');
    this.cartService.clearCartItems();
  }
  navigateToHome() {
    this.router.navigate(['/home']);
    this.cartService.clearCartItems();
  }
}
