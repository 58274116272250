import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, ObservableInput, Subscription, tap } from 'rxjs';
import { AccountService } from '../../../core/auth/account.service';
import { Account } from '../../../core/auth/account.model';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CartService } from '../../../cart/cart.service';
import { SocketService } from '../../../orders/socket.service';
import { OrdersService } from '../../../orders/orders.service';
import { ToastrService } from 'ngx-toastr';
interface StatusMapping {
  userStatus: string;
  actions: string[];
}
@Component({
  selector: 'jhi-details-purchase',
  standalone: true,
  imports: [NgbModule, CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './details-purchase.component.html',
  styleUrl: './details-purchase.component.scss',
})
export class DetailsPurchaseComponent {
  @ViewChild('modalCartVide') modalCartVide: any;
  @ViewChild('modalDetailsCommande') modalDetailsCommande: any;

  account!: Account;
  purchase_id: any;
  purchaseDetails: any;
  room: string = '';
  messages: any[] = [];
  message = '';
  fileUrlsFromOvh: string[] = [];
  isFilesExistFromOvh = false;
  prescriptionFilesFromOvh: any[] = [];
  vitalCardFilesFromOvh: any[] = [];
  mutualCardFilesFromOvh: any[] = [];
  prescriptionFiles: File[] = [];
  vitalCardFiles: File[] = [];
  mutualCardFiles: File[] = [];
  reportForm: FormGroup;

  statusMappings: Record<string, StatusMapping> = {
    CREATED_WITH_PRESCRIPTION: { userStatus: 'NOUVELLE', actions: ['ANNULER', 'VOIR DÉTAILS'] },
    IN_PROGRESS: { userStatus: 'EN COURS', actions: ['ANNULER', 'VOIR DÉTAILS'] },
    PAYMENT_INSTANCE: { userStatus: 'PRETE', actions: ['PAYER', 'ANNULER'] },
    PACKAGING: { userStatus: 'EMBALLAGE', actions: ['VOIR DÉTAILS'] },
    READY: { userStatus: 'PRETE', actions: ['VOIR DÉTAILS'] },
    DELIVERING: { userStatus: 'EN LIVRAISON', actions: ['VOIR DÉTAILS'] },
    DELIVERED: { userStatus: 'ARCHIVE', actions: ['VOIR DÉTAILS'] },
    RECOVERING: { userStatus: 'EN LIVRAISON', actions: ['VOIR DÉTAILS'] },
    RECOVERED: { userStatus: 'ARCHIVE', actions: ['VOIR DÉTAILS'] },
    RETRACTED: { userStatus: 'ARCHIVÉ', actions: ['VOIR DÉTAILS'] },
    CANCELED: { userStatus: 'ARCHIVÉ', actions: ['VOIR DÉTAILS'] },
    REJECTED: { userStatus: 'ARCHIVÉ', actions: ['VOIR DÉTAILS'] },
    LOST: { userStatus: 'ARCHIVÉ', actions: [] },
    ARCHIVED: { userStatus: '---', actions: [] },
  };
  predefinedMessages = [
    { id: 1, content: 'Le client a été extrêmement impoli et agressif envers le personnel lors de sa dernière visite' },
    { id: 2, content: 'Le client a tenté de faire prescrire un médicament contrôlé en fournissant une ordonnance qui semble suspecte' },
  ];
  @ViewChild('msgHistory') private msgHistory!: ElementRef;

  private messageSubscription!: Subscription;
  numCommande: any;
  constructor(
    private socketService: SocketService,
    private route: ActivatedRoute,
    private orderService: OrdersService,
    private accountService: AccountService,
    public cartService: CartService,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.getPurchaseId();
    this.loadProfile();
    window.scrollTo(0, 0);
    this.initForm();
    // Initialiser la socket
    this.route.paramMap.subscribe(params => {
      this.room = params.get('purchase_id')!;
      const query = `username=walid&room=${this.purchase_id}`;
      this.socketService.setQuery(query);
      this.initializeChat();
    });

    // Gestion des messages de la socket
    this.messageSubscription = this.socketService.socketResponse$.subscribe(response => {
      if (response) {
        this.messages.push({
          sender: response.user.name || 'Server',
          text: response.text,
          time: new Date(response.createdAt).toLocaleTimeString(),
          received: response.received,
        });
      }
    });
    this.numCommande = this.cartService.getNumCommande();
    this.getAllFilesUrlFromOvh();
  }
  initForm(): void {
    this.reportForm = this.fb.group({
      pharmacistId: [null, Validators.required],
      clientId: [null, Validators.required],
      predefinedMessages: this.fb.array([]),
      additionalComment: ['', Validators.required],
    });
  }
  predefinedMessagesList: any[] = [];
  createPredefinedMessages(): FormGroup[] {
    return this.predefinedMessagesList.map(message =>
      this.fb.group({
        id: [message.id],
        content: [message.content],
      }),
    );
  }

  submitReport(): void {
    const reportData = this.reportForm.value;
    reportData.predefinedMessages = this.predefinedMessages;

    this.orderService.addReports(reportData).subscribe({
      next: response => {
        console.log('Signalement envoyé avec succès', response);
        this.toastr.success('Signalement envoyé avec succès');
      },
      error: error => {
        console.error("Erreur lors de l'envoi du signalement", error);
        this.toastr.error("Erreur lors de l'envoi du signalement");
      },
    });
  }
  listreports: any;
  getReports(clientId: any) {
    this.orderService.getReports(clientId).subscribe({
      next: reports => {
        console.log('Signalements récupérés avec succès', reports);
        this.listreports = reports;
      },
      error: error => {
        console.error('Erreur lors de la récupération des signalements', error);
      },
    });
  }

  getAllFilesUrlFromOvh(): void {
    this.orderService.getFileUrl(this.purchase_id).subscribe(res => {
      console.log('Réponse du serveur:', res); // Vérifiez la réponse du serveur
      this.prescriptionFilesFromOvh = res.prescription || [];
      this.vitalCardFilesFromOvh = res.vital_card || [];
      this.mutualCardFilesFromOvh = res.mutual_card || [];

      const files = [...res.prescription, ...res.vital_card, ...res.mutual_card];
      console.log('Clés de fichiers:', files); // Vérifiez les clés de fichiers

      if (files.length > 0) {
        this.isFilesExistFromOvh = true;
        this.prescriptionFilesFromOvh = res.prescription || [];
        this.fileUrlsFromOvh = [];
        console.log("Fichiers d'ordonnance:", this.prescriptionFilesFromOvh); // Vérifiez les fichiers d'ordonnance
        files.forEach((fileKey, index) => {
          this.orderService
            .getImage('test-documents-cmd-ordonnance', fileKey)
            .then(data => {
              const blob = new Blob([data.Body], { type: data.ContentType });
              const url = URL.createObjectURL(blob);
              this.fileUrlsFromOvh.push(url);
              console.log('URL de fichier:', url); // Vérifiez les URL créées

              if (this.prescriptionFiles.includes(fileKey)) {
                this.prescriptionFilesFromOvh[index] = url;
              } else if (this.vitalCardFiles.includes(fileKey)) {
                this.vitalCardFilesFromOvh[index] = url;
              } else if (this.mutualCardFiles.includes(fileKey)) {
                this.mutualCardFilesFromOvh[index] = url;
              }
            })
            .catch(err => {
              console.error("Erreur lors du chargement de l'image :", err);
            });
        });
      }
    });
  }
  loadProfile(): void {
    this.accountService.getAuthenticatedUser().subscribe(res => {
      this.account = res;
      this.reportForm.patchValue({
        pharmacistId: this.account?.applicationUser?.pharmacy?.id,
      });
    });
  }

  getPurchaseId(): void {
    this.route.paramMap.subscribe(params => {
      this.purchase_id = +params.get('purchase_id')!;
      this.getPurchaseDetails(this.purchase_id);
    });
  }

  getPurchaseDetails(purchase_id: any): void {
    this.orderService.getPurchaseById(purchase_id).subscribe({
      next: res => {
        this.purchaseDetails = res;
        this.getReports(this.purchaseDetails?.user?.id);
        this.reportForm.patchValue({
          clientId: this.purchaseDetails?.user?.id,
        });
        console.log(res);
      },
      error: err => {
        console.log(err);
      },
    });
  }

  initializeChat(): void {
    this.socketService.joinRoom(this.room);
    // this.socketService.getMessagesForRoom(this.room).subscribe(
    //   messages => {
    //     this.messages = messages.map((msg: any) => ({
    //       sender: 'Client',
    //       text: msg.content,
    //       time: new Date(msg.createdDateTime).toLocaleTimeString(),
    //     }));
    //   },
    //   error => {
    //     console.error('Error fetching messages:', error);
    //   },
    // );
  }
  sendMessage(): void {
    if (this.message.trim()) {
      let data = {
        message: this.message,
        content: this.message,
        userId: this.account.id,
        room: this.room,
        platforme: 'web',
      };
      this.socketService.sendMessage(data);
      this.messages.push({
        sender: 'Vous',
        text: this.message,
        time: new Date().toLocaleTimeString(),
      });
      this.message = '';
    }
  }

  openModal() {
    this.modalService.open(this.modalCartVide, { size: 's', centered: true });
  }
  openModalCommande() {
    this.modalService.open(this.modalDetailsCommande, { size: 'l', centered: true });
  }
  getUserStatus(status: string): string {
    return this.statusMappings[status]?.userStatus || 'UNKNOWN';
  }

  getActions(status: string): string[] {
    return this.statusMappings[status]?.actions || [];
  }
  navigateToPurchaseLite() {
    this.router.navigate(['/pharmacie/purchases']);
  }
  addAmountValue: boolean = false;
  extraAmount: string = '';
  addAmount() {
    this.addAmountValue = true;
    console.log(this.addAmountValue, 'addAmountValue');
  }
  addExtraAmount() {
    const data = {
      id: this.purchase_id,
      extraAmount: this.extraAmount ? parseFloat(this.extraAmount) : 0,
    };
    this.orderService.addExtraAmountPurchase(data, this.purchase_id).subscribe(
      response => {
        this.toastr.success('Montant ajouté avec succès');
        this.extraAmount = '';
        this.addAmountValue = false;
        this.getPurchaseDetails(this.purchase_id);
      },
      error => {
        this.toastr.error("Une erreur est survenue lors de l'ajout du montant");
      },
    );
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    this.socketService.disconnect();
  }
}
